import { cn } from '@finn/ui-utils';
import { ReactNode } from 'react';

type Props = {
  noPaddingTop?: boolean;
  className?: string;
  html_id?: string;
  children?: ReactNode;
  isBlog?: boolean;
};

const SpacingWrapper = ({
  children,
  noPaddingTop,
  className,
  html_id,
  isBlog,
}: Props) => (
  <div
    className={cn(
      noPaddingTop ? 'pt-0' : 'pt-24 md:pt-40',
      { 'pt-6 md:pt-14': isBlog && !noPaddingTop },
      className
    )}
    id={html_id}
  >
    {children}
  </div>
);

export default SpacingWrapper;
